import { scrollToTargetWithOffset } from "./../helpers/scroll-to";
import React from "react";

import About from "../components/about";
import GalleryVerticalFotoHome from "../components/gallery-vertical-picture";
import ScacchieraHome from "../components/scacchiera-home";
import { TopDescription } from "../components/top-description";
import Contact from "../components/contact";

export default function Home() {
	return (
		<>
			<section id="hero">
				<div className="container hero-container">
					<h1>
						Benvenuti in<br></br> Polaris Production
					</h1>

					<div
						className="btn-scroll scrollto"
						title="Scopri di più"
						onClick={() => scrollToTargetWithOffset("topDescription")}
					>
						<i className="fi fi-rr-angle-down"></i>
					</div>
				</div>
			</section>
			<div id="topDescription">
				<TopDescription
					notDisplayH2="true"
					title=""
					description={`dove con l'arte della fotografia e del cinema raccontiamo la vostra storia in modo unico e originale.`}
				/>
			</div>
			<ScacchieraHome />
			<TopDescription
				notDisplayH2="true"
				title=""
				description={`Dalla realizzazione di campagne pubblicitarie di moda ai reportage di eventi privati e pubblici, siamo impegnati a trasformare i vostri obiettivi in realtà con la nostra combinazione unica di creatività, tecnologia e professionalità. Vi guideremo passo passo, dalla pianificazione, alla produzione fino alla condivisione.
				Siamo appassionati di quello che facciamo e lavoriamo con dedizione per garantire che ogni cliente sia soddisfatto del nostro lavoro.`}
			/>
			<GalleryVerticalFotoHome />

			<About />
			<Contact />
		</>
	);
}
