import React from "react";
import { logo } from "../media/all-images";

export default function About() {
	return (
		<section id="about">
			<div className="container">
				<div className="d-flex flex-column flex-lg-row align-items-center content">
					<div className="col col-lg-5">
						<img src={logo} alt="Luca sul set" className="w-100" />
					</div>
					<p className="col col-lg-7">
						Proprio come la stella Polare ha condotto i navigatori noi siamo
						pronti ad accompagnarvi nella vostra avventura.
						<br /> Come la stella che guida la nave verso la sua destinazione,
						siamo qui per fornirvi la nostra esperienza e professionalità.
					</p>
				</div>
			</div>
		</section>
	);
}
