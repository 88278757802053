import React, { useRef } from "react";
import MediaQuery from "../helpers/media-query";
import { useLocation } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function Contact() {
	const { isNotDesktop, isDesktopLarge } = MediaQuery();
	const location = useLocation();
	const currentUrl = location.pathname;

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm(
				process.env.REACT_APP_SERVICE,
				process.env.REACT_APP_TEMPLATE,
				form.current,
				process.env.REACT_APP_EMAILJS_USER_ID,
				{
					personalData: e.target.personalData.checked ? "checked" : "unchecked",
				}
			)
			.then(
				(result) => {
					// console.log(result.text);
					e.target.reset();
				},
				(error) => {
					// console.log(error.text);
				}
			);
	};

	return (
		<section id="contact" className="contact">
			<div
				className="container"
				style={{ marginTop: currentUrl === "/contact" && "60px" }}
			>
				<div className="section-title">
					<span>Contatti</span>
					<h2>Contatti</h2>
				</div>

				<div className="row">
					<div className="col-lg-7 mb-4 mb-lg-0">
						<div className="row">
							<div className="col-md-12">
								<div className="info-box py-0">
									<p className="description">
										Da oltre 10 anni siamo presenti a Milano, dove abbiamo avuto
										la fortuna di poter collaborare con prestigiosi brand,
										spostandoci poi su tutto il territorio nazionale mettendo a
										disposizione dei clienti la nostra esperienza e
										professionalità nel realizzare progetti unici ed originali.
										<br />
										La nostra storia nasce in Abruzzo, terra autentica, ricca di
										bellezze naturali e culturali dalle quali prendiamo spunto
										per portare coraggiosamente sempre più in alto il nostro
										lavoro.
									</p>
								</div>
							</div>

							<div className="col-md-4">
								<div className="info-box mt-4 channels">
									<i className="fi fi-rr-envelope"></i>
									<h3>Email</h3>
									{!isNotDesktop && !isDesktopLarge ? (
										<p>
											info@<br></br>polarisproduction.com
										</p>
									) : (
										<p>info@polarisproduction.com</p>
									)}
								</div>
							</div>
							<div className="col-md-4">
								<div className="info-box mt-4 channels">
									<i className="fi fi-rr-phone-call"></i>
									<h3>Chiamaci</h3>
									<p>+39 3491671240</p>
								</div>
							</div>
							<div className="col-md-4">
								<div className="info-box mt-4 channels">
									<i className="fi fi-rr-share"></i>
									<h3>Profili Social</h3>
									<div className="social-links">
										<a
											href="https://www.facebook.com/Polarisproductionsrls"
											target="_blank"
											rel="noreferrer"
											className="facebook"
										>
											<i className="fi fi-brands-facebook"></i>
										</a>
										<a
											href="https://www.instagram.com/polarisproduction/"
											target="_blank"
											rel="noreferrer"
											className="instagram"
										>
											<i className="fi fi-brands-instagram"></i>
										</a>
										<a
											href="https://vimeo.com/polarisproduction"
											target="_blank"
											rel="noreferrer"
											className="vimeo"
										>
											<i className="fi fi-brands-vimeo"></i>
										</a>
									</div>
								</div>
							</div>

							<div>
								<div className="col-md-12">
									<div className="info-box location mt-4 d-flex justify-content-center">
										<i className="fi fi-rr-marker me-4"></i>
										<p>Milano, Chieti</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="col-lg-5">
						<form ref={form} onSubmit={sendEmail} className="email-form">
							<div>
								<div className="row">
									<div className="col-md-6 form-group">
										<input
											type="text"
											name="name"
											className="form-control"
											id="name"
											placeholder=" "
											required
										/>
										<label className="form__label extra-padding">Nome</label>
									</div>
									<div className="col-md-6 form-group mt-3 mt-md-0">
										<input
											type="email"
											className="form-control"
											name="email"
											id="email"
											placeholder=" "
											required
										/>
										<label className="form__label extra-padding">Email</label>
									</div>
								</div>
								<div className="form-group mt-3">
									<input
										type="text"
										className="form-control"
										name="subject"
										id="subject"
										placeholder=" "
										required
									/>
									<label className="form__label">Oggetto</label>
								</div>
								<div className="form-group mt-3">
									<textarea
										className="form-control"
										name="message"
										rows="10"
										placeholder=" "
										required
									></textarea>
									<label className="form__label">Message</label>
								</div>
								<div className="d-flex align-items-center mt-3">
									<input
										className="form-check-input mt-0 p-0"
										style={{
											marginRight: "0.5rem",
											aspectRatio: "1",
										}}
										type="checkbox"
										name="personalData"
										value="accettati"
										id="personalData"
										required
									/>
									<label
										className="form-check-label"
										htmlFor="personalData"
										style={{ fontSize: "14px" }}
									>
										Letta l'informativa, autorizzo al {"  "}
										<a
											href="document/PrivacyPolicy_PolarisProduction.pdf"
											target="_blank"
											rel="noreferrer"
											className="personal-data-link"
										>
											trattamento dei miei dati personali
										</a>
										*
									</label>
								</div>
							</div>
							<div className="text-center mt-3">
								<button type="submit">Invia Messaggio</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</section>
	);
}
