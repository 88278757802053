export const scrollToTargetWithOffset = (id) => {
	var element = document.getElementById(id);
	var headerOffset = 60;
	var elementPosition = element.getBoundingClientRect().top;
	var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

	return window.scrollTo({
		top: offsetPosition,
		behavior: "smooth",
	});
};
