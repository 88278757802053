import React from "react";
import MyCarousel from "../components/carousel/carousel";
import { TopDescription } from "../components/top-description";
import { useEffect, useState } from "react";
import { getRecords, AIRTABLE_TABLE_FOTOCORPORATE } from "../helpers/airtable";
import { ComponentLoader } from "../components/loader/loader";

const FotoCorporateCarousel = () => {
	const [records, setRecords] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_FOTOCORPORATE);
			setRecords(data);
		};
		fetchData();
	}, []);

	if (!records) {
		return (
			<div>
				<ComponentLoader />
			</div>
		);
	} else {
		return records.map((r, i) => (
			<div
				className={`container d-flex row single-event-section ${
					i % 2 === 0 && "even"
				}`}
				key={i}
			>
				<div className="foto-event-section col col-md-8 col-lg-7">
					<MyCarousel
						client={r.fields?.Name ? r.fields.Name : `Foto${i}`}
						images={r?.fields["Foto"]}
					/>
				</div>
				<div className="col col-md-4 col-lg-5 align-self-center text-center">
					<h2>{r.fields.Name}</h2>
				</div>
			</div>
		));
	}
};

export default function FotoCorporate() {
	return (
		<div className="first-element" id="fotoCorporate">
			<TopDescription
				title="FOTO CORPORATE"
				description="Benvenuti nell'arte della fotografia aziendale. Siamo qui per aiutarvi a rafforzare la vostra immagine aziendale e a promuovere la vostra attività in modo efficace. Portiamo pulizia, ricercatezza e un pizzico di simpatia sul set, perché il ritratto aziendale è una cosa seria. Ci occupiamo anche della produzione di immagini di alta qualità per still life di prodotto, gioielli e accessori. La nostra esperienza e passione per la fotografia ci permette di catturare la bellezza e l'unicità dei vostri prodotti attraverso immagini straordinarie e coinvolgenti."
				// cn="pt-4"
			/>
			<FotoCorporateCarousel />
		</div>
	);
}
