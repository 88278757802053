import { useMediaQuery } from "react-responsive";

const MediaQuery = () => {
	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });
	const isMobileMax = useMediaQuery({ query: "(max-width: 576px)" });
	const isTablet = useMediaQuery({ query: "(max-width: 768px)" });
	const isNotDesktop = useMediaQuery({ query: "(max-width: 992px)" });
	const isDesktopLarge = useMediaQuery({ query: "(min-width: 1200px)" });

	return { isMobile, isMobileMax, isTablet, isNotDesktop, isDesktopLarge };
};

export default MediaQuery;
