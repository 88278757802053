import { TopDescription } from "./../components/top-description";
import React from "react";
import { useEffect, useState } from "react";
import { getRecords, AIRTABLE_TABLE_FOTOADV } from "../helpers/airtable";
import { ComponentLoader } from "../components/loader/loader";

const FotoADV = () => {
	const [records, setRecords] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_FOTOADV);
			setRecords(data);
		};

		fetchData();
	}, []);

	if (!records) {
		return (
			<div>
				<ComponentLoader />
			</div>
		);
	} else {
		return records.map((r, i) => (
			<img
				src={r.fields["Foto"][0].url}
				alt={r.fields.Name ? r.fields.Name : " "}
				className="w-100"
				key={i}
			/>
		));
	}
};

export default function FotoAdv() {
	return (
		<div className="first-element">
			<TopDescription
				// cn="pt-4"
				title="FASHION FOTO"
				description="Benvenuti nel mondo della fotografia di moda. Realizziamo campagne fotografiche capaci di catturare lo stile, l'eleganza e le personalità uniche delle vostre collezioni. La nostra esperienza e passione per la fashion photography ci permette di creare immagini straordinarie che esaltino la bellezza dei vostri prodotti. Ci rivolgiamo a voi con soluzioni complete e professionali fornendovi tutte le figure necessarie alla realizzazione del servizio, dallo styling ed i casting, fino alla ricerca di make up artist e location di prestigio."
			/>
			<div className="gallery-photo container">
				<FotoADV />
			</div>
		</div>
	);
}
