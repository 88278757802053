import React, { useState } from "react";
import MediaQuery from "../helpers/media-query";
import { Link } from "react-router-dom";
export function Header() {
	const [navOpen, setNavOpen] = useState(false);

	function onChange() {
		setNavOpen(!navOpen);
	}
	function closeNav() {
		setNavOpen(false);
	}
	const { isNotDesktop } = MediaQuery();
	const nav_open = navOpen ? "nav_open" : null;

	const navbarCN = isNotDesktop ? "nav" : "navbar";
	const navbarUlCN = isNotDesktop
		? navOpen
			? "nav__list"
			: "nav_list_closed nav__list"
		: null;
	const navbarListCN = isNotDesktop ? "nav__item" : null;
	const navbarListAnchorCN = isNotDesktop ? "nav__link" : null;

	return (
		<header
			className={`${nav_open} header d-flex align-items-center`}
			id="header"
			style={{ position: "fixed" }}
		>
			<div className="container-fluid container d-flex align-items-center justify-content-between">
				<h2 className="mb-0 logo-header">
					<Link to="/">
						<span className="text-white text-logo">POLARIS PRODUCTION</span>
					</Link>
				</h2>
				{isNotDesktop && (
					<div className="nav-toogle-container">
						<button
							className="nav-toogle"
							aria-label="toogle navigation"
							onClick={onChange}
						>
							<span className="hamburger"></span>
						</button>
					</div>
				)}

				<nav className={`${navbarCN}`} id="navbar">
					<ul className={`${navbarUlCN}`} onClick={closeNav}>
						<li className={`${navbarListCN} dropdown no_hover`}>
							<h6 className={`${navbarListAnchorCN} text-center `}>
								VIDEO<i className="fi fi-rr-angle-small-down"></i>
							</h6>
							<ul className="dropdown-list-videofoto">
								<li>
									<Link to="video-adv">ADV</Link>
								</li>
								<li>
									<Link to="/video-corporate">CORPORATE</Link>
								</li>
								<li>
									<Link to="/event">EVENT</Link>
								</li>
							</ul>
						</li>

						<li className={`${navbarListCN} dropdown no_hover`}>
							<h6 className={`${navbarListAnchorCN} text-center`}>
								FOTO<i className="fi fi-rr-angle-small-down"></i>
							</h6>
							<ul className="dropdown-list-videofoto">
								<li>
									<Link to="/foto-adv">ADV</Link>
								</li>
								<li>
									<Link to="/foto-corporate">CORPORATE</Link>
								</li>
								<li>
									<Link to="/event">EVENT</Link>
								</li>
							</ul>
						</li>
						<li className={`${navbarListCN}`}>
							<Link className={`${navbarListAnchorCN}`} to="/social">
								SOCIAL
							</Link>
						</li>
						<li className={`${navbarListCN}`}>
							<Link className={`${navbarListAnchorCN}`} to="contact">
								CONTACT
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
}
