import React, { useEffect, useRef, useState } from "react";
import MediaQuery from "../helpers/media-query";

import { getRecords, AIRTABLE_TABLE_VIDEOHOME } from "../helpers/airtable";
import { ComponentLoader } from "./loader/loader";

export default function ScacchieraHome() {
	const [containerWidth, setContainerWidth] = useState(0);
	const { isMobileMax, isNotDesktop } = MediaQuery();

	useEffect(() => {
		const handleResize = () => {
			setContainerWidth(containerRef.current.offsetWidth);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const containerRef = useRef(null);
	const numberOfColumn = isMobileMax ? 1 : isNotDesktop ? 2 : 3;
	const height = containerWidth / (16 / 9) / numberOfColumn;

	const SingleVideoHome = () => {
		const [records, setRecords] = useState(null);

		useEffect(() => {
			const fetchData = async () => {
				const data = await getRecords(AIRTABLE_TABLE_VIDEOHOME);
				setRecords(data);
			};
			fetchData();
		}, []);

		if (!records) {
			return (
				<div>
					<ComponentLoader />
				</div>
			);
		} else {
			return records.map((r, i) => {
				if (r.fields.MostraSuSito && i < 6) {
					return (
						<div className="video" key={i} style={{ height: `${height}px` }}>
							{r.fields.VideoID && (
								<iframe
									title={r.fields.Name ? r.fields.Name : " "}
									src={`https://player.vimeo.com/video/${r.fields?.VideoID}?byline=0`}
									height="100%"
									width="100%"
									frameBorder="0"
									allow="fullscreen; picture-in-picture"
									allowFullScreen
								/>
							)}
						</div>
					);
				}
				return null;
			});
		}
	};

	return (
		<section>
			<div className="video-scacchiera-home container" ref={containerRef}>
				<SingleVideoHome />
			</div>
		</section>
	);
}
