import { Header } from "./components/header";
import React from "react";
import {
	Home,
	FotoAdv,
	FotoCorporate,
	VideoAdv,
	VideoCorporate,
	Event,
	Social,
} from "./pages/all-website-pages";

import "./App.css";

import { Route, Routes } from "react-router-dom";
import Contact from "./components/contact";
import Footer from "./components/footer";
import PortfolioPage from "./pages/portfolio";

function App() {
	return (
		<>
			<div id="APP">
				<Header />
				<Routes>
					<Route path="/" element={<Home />} exact />
					<Route path="/foto-adv" element={<FotoAdv />} />
					<Route path="/foto-corporate" element={<FotoCorporate />} />
					<Route path="/video-adv" element={<VideoAdv />} />
					<Route path="/video-corporate" element={<VideoCorporate />} />
					<Route path="/event" element={<Event />} />
					<Route path="/social" element={<Social />} />
					<Route path="/contact" element={<Contact />} />
					<Route path="/portfolio" element={<PortfolioPage />} />
				</Routes>

				<Footer />
			</div>
		</>
	);
}

export default App;
