import React from "react";
export function TopDescription({ title, description, cn, notDisplayH2 }) {
	function makeDescriptionWithMultipleLines() {
		return description.split("\n").map((str, index) => (
			<p key={index} className="text">
				{str}
			</p>
		));
	}

	return (
		<div className="initial-description-page">
			<div className="container">
				<h2 style={{ display: { notDisplayH2 } && "none" }}>{title}</h2>
				<h6 className={`text ${cn}`}>{makeDescriptionWithMultipleLines()}</h6>
			</div>
		</div>
	);
}
