import React from "react";
import "./carousel.css";
import MediaQuery from "../../helpers/media-query";

export default function MyCarousel({ client, images }) {
	const { isDesktopLarge } = MediaQuery();

	let idCarousel = client.replace(/ /g, "_");

	return (
		<div id={idCarousel} className="carousel slide" data-bs-ride="false">
			<div className="carousel-indicators">
				<button
					type="button"
					data-bs-target={`#${`#${idCarousel}`}`}
					data-bs-slide-to="0"
					className="active"
					aria-current="true"
					aria-label="Slide 1"
				></button>

				{
					// eslint-disable-next-line
					images.map((i, index) => {
						if (index >= 1) {
							return (
								<button
									type="button"
									data-bs-target={`#${idCarousel}`}
									data-bs-slide-to={index}
									aria-label={`Slide ${index + 1}`}
									key={index}
								></button>
							);
						}
					})
				}
			</div>
			<div className="carousel-inner">
				<div className="carousel-item active" style={{ maxHeight: "75vh" }}>
					<img
						src={images[0].url}
						className="d-block"
						style={{
							maxWidth: "100%",
							paddingInline:
								images[0]?.height > images[0]?.width &&
								isDesktopLarge &&
								"7rem",
						}}
						alt="..."
					/>
				</div>
				{images.length > 0 &&
					// eslint-disable-next-line
					images?.map((i, index) => {
						if (index >= 1) {
							return (
								<div
									className="carousel-item"
									key={index}
									style={{ maxHeight: "75vh" }}
								>
									<img
										src={i.url}
										className="d-block "
										style={{
											maxWidth: "100%",
											paddingInline:
												i?.height > i?.width && isDesktopLarge && "7rem",
										}}
										alt="..."
									/>
								</div>
							);
						}
					})}
			</div>
			<button
				className="carousel-control-prev"
				type="button"
				data-bs-target={`#${idCarousel}`}
				data-bs-slide="prev"
			>
				<span className="carousel-control-prev-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Previous</span>
			</button>
			<button
				className="carousel-control-next"
				type="button"
				data-bs-target={`#${idCarousel}`}
				data-bs-slide="next"
			>
				<span className="carousel-control-next-icon" aria-hidden="true"></span>
				<span className="visually-hidden">Next</span>
			</button>
		</div>
	);
}
