import React, { useEffect, useRef, useState } from "react";
import { ComponentLoader } from "../components/loader/loader";

import { TopDescription } from "../components/top-description";

import { getRecords, AIRTABLE_TABLE_VIDEOADV } from "../helpers/airtable";

export default function VideoAdv() {
	const [records, setRecords] = useState(null);
	const [containerWidth, setContainerWidth] = useState(0);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_VIDEOADV);
			setRecords(data);
		};
		fetchData();
	}, []);

	useEffect(() => {
		const handleResize = () => {
			setContainerWidth(containerRef.current.offsetWidth);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const containerRef = useRef(null);
	const height = containerWidth / (16 / 9);

	const SingleVideoADV = () => {
		if (!records) {
			return (
				<div>
					<ComponentLoader />
				</div>
			);
		} else {
			return records.map((r, i) => {
				if (r.fields.MostraSuSito) {
					return (
						<div className="video-adv-client-section" key={i}>
							<h2>{r.fields?.Name ? r.fields.Name : " "}</h2>
							<div
								className="video w-100"
								key={i}
								style={{ height: `${height}px` }}
							>
								{r.fields.VideoID && (
									<iframe
										title={r.fields?.Name ? r.fields.Name : " "}
										src={`https://player.vimeo.com/video/${r.fields?.VideoID}`}
										height="100%"
										width="100%"
										frameBorder="0"
										allow="autoplay; fullscreen; picture-in-picture"
										allowFullScreen
									/>
								)}
							</div>
						</div>
					);
				}
				return null;
			});
		}
	};
	return (
		<div className="first-element" id="videoADV">
			<TopDescription
				title="FASHION VIDEO"
				description={`Benvenuti nel mondo della moda e dell'arte cinematografica. Siamo specializzati nella realizzazione di fashion film che uniscono l'eleganza e lo stile della moda con la creatività e la maestria dell'arte cinematografica.`}
				// cn="pt-4"
			/>
			<div className="container container-video" ref={containerRef}>
				<SingleVideoADV />
			</div>
		</div>
	);
}
