import React from "react";

export default function Footer() {
	return (
		<footer id="footer">
			<div className="container">
				<h3>Polaris Production</h3>

				<div className="company-info">
					<p>Polaris Production SRLS. Via Viano 55, Filetto 66030 (CH)</p>
					<p>P.IVA: 02613250691</p>
				</div>
				<div className="copyright">
					&copy; Copyright{" "}
					<strong>
						<span>Polaris Production</span>
					</strong>
					. All Rights Reserved
				</div>
			</div>
		</footer>
	);
}
