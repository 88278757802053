const AIRTABLE_BASE_URL = "https://api.airtable.com/v0/appBqD8T34ncgdbmF/";
const AIRTABLE_TABLE_FOTOHOME =
	"tbl8PzQUKDdYz1JFp?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_FOTOADV =
	"tblc8CLron91GTvn9?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_FOTOCORPORATE =
	"tblslM4W0qQU6OYoo?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_EVENT =
	"tblYA06i166wWk1ri?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_VIDEOADV =
	"tblBfuRZRJPobRQxC?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_VIDEOCORPORATE =
	"tblDi5C6ojRgvKATL?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_VIDEOSOCIAL =
	"tblGXZY0IIGU8hN8r?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_VIDEOHOME =
	"tblkwY11xMIgANge3?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_TABLE_PORTFOLIO =
	"tblUT8G3QhzM6FnPv?sort%5B0%5D%5Bfield%5D=SortID&sort%5B0%5D%5Bdirection%5D=asc";
const AIRTABLE_AUTH_TOKEN =
	"Bearer patq4shpqIshsv6nN.d2c07965ee61046b34a06f5882167c03707eb7ba48b3006450e8a9fd910ac517";

export {
	AIRTABLE_TABLE_FOTOHOME,
	AIRTABLE_TABLE_FOTOADV,
	AIRTABLE_TABLE_FOTOCORPORATE,
	AIRTABLE_TABLE_EVENT,
	AIRTABLE_TABLE_VIDEOADV,
	AIRTABLE_TABLE_VIDEOCORPORATE,
	AIRTABLE_TABLE_VIDEOSOCIAL,
	AIRTABLE_TABLE_VIDEOHOME,
	AIRTABLE_TABLE_PORTFOLIO,
};

export const getRecords = async (table) => {
	const response = await fetch(`${AIRTABLE_BASE_URL}${table}`, {
		headers: {
			Authorization: AIRTABLE_AUTH_TOKEN,
		},
	});

	const data = await response.json();
	let onlyValidRecords = data.records.filter(
		(r) =>
			r.fields &&
			Object.keys(r.fields).length > 0 &&
			r.fields.MostraSuSito === true
	);

	return onlyValidRecords;
};
