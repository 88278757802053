import React, { useEffect, useState } from "react";
import { getRecords, AIRTABLE_TABLE_FOTOHOME } from "../helpers/airtable";
import { ComponentLoader } from "./loader/loader";

export default function GalleryVerticalFotoHome() {
	const [records, setRecords] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_FOTOHOME);
			setRecords(data);
		};
		fetchData();
	}, []);

	const FotoHome = () => {
		if (!records) {
			return (
				<div>
					<ComponentLoader />
				</div>
			);
		} else {
			return records.map((r, i) => {
				if (r.fields.MostraSuSito) {
					return (
						<img
							key={i}
							src={r.fields["Foto"][0].url}
							alt={r.fields?.name ? r.fields?.name : " "}
							className="w-100"
						/>
					);
				}
				return null;
			});
		}
	};

	return (
		<div className="container vertical-photo-home">
			<FotoHome />
		</div>
	);
}
