import React, { useEffect, useState } from "react";
import { TopDescription } from "../components/top-description";
import MyCarousel from "../components/carousel/carousel";
import { getRecords, AIRTABLE_TABLE_PORTFOLIO } from "../helpers/airtable";
import { ComponentLoader } from "../components/loader/loader";

const PortfolioCarouselPlusVideo = () => {
	const [records, setRecords] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_PORTFOLIO);
			setRecords(data);
		};
		fetchData();
	}, []);

	if (!records) {
		return (
			<div>
				<ComponentLoader />
			</div>
		);
	} else {
		return records.map((r, i) => {
			if (r.fields.MostraSuSito) {
				return (
					<div key={i} className="container single-event-section">
						<h2 className="text-center pb-4">
							{r.fields?.Name ? r.fields.Name : " "}
						</h2>
						<div
							className={`d-flex row ${i % 2 === 0 && "even"} ${
								(!r.fields["Foto"] || !r.fields.VideoID) &&
								"justify-content-center"
							}`}
						>
							{r.fields["Foto"] && (
								<div className="foto-event-section col pt-4 pt-lg-0 col-lg-6">
									<MyCarousel
										client={r.fields?.Name ? r.fields.Name : `Foto${i}`}
										images={r.fields["Foto"]}
									/>
								</div>
							)}
							{r.fields.VideoID && (
								<div className="col col-lg-6 align-self-center justify-content-center">
									<div className="video">
										<iframe
											title={r.fields.Name}
											src={`https://player.vimeo.com/video/${r.fields.VideoID}`}
											height="100%"
											width="100%"
											frameBorder="0"
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				);
			}
			return null;
		});
	}
};

export default function PortfolioPage() {
	const [descriptionText, setDescriptionText] = useState("");
	const [hasLoaded, setLoaded] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_PORTFOLIO);
			let filteredData = data.filter(
				(d) => d.fields && d.fields["Testo"] && d.fields["Testo"].length > 0
			);
			filteredData.length
				? setDescriptionText(filteredData[0].fields.Testo)
				: setDescriptionText("");
			setLoaded(true);
		};
		fetchData();
	}, []);

	if (!hasLoaded) {
		return (
			<div>
				<ComponentLoader />
			</div>
		);
	} else {
		return (
			<div className="first-element" id="event">
				{descriptionText && (
					<TopDescription
						title="REPORTAGE EVENTI"
						description={descriptionText}
						// cn="pt-4"
					/>
				)}
				<PortfolioCarouselPlusVideo />
			</div>
		);
	}
}
