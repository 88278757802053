import React, { useEffect, useRef, useState } from "react";
import { TopDescription } from "../components/top-description";
import { getRecords, AIRTABLE_TABLE_VIDEOSOCIAL } from "../helpers/airtable";
import { ComponentLoader } from "../components/loader/loader";
import MediaQuery from "../helpers/media-query";

export default function Social() {
	const [containerWidth, setContainerWidth] = useState(0);
	const { isMobileMax, isNotDesktop } = MediaQuery();

	useEffect(() => {
		const handleResize = () => {
			setContainerWidth(containerRef.current.offsetWidth);
		};

		handleResize();

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const containerRef = useRef(null);
	const offsetHeight = isMobileMax ? 35 : isNotDesktop ? 27 : 25;
	const numberOfColumn = isMobileMax ? 1 : isNotDesktop ? 2 : 3;
	const height = containerWidth / (9 / 16) / numberOfColumn - offsetHeight;

	const SingleVideoSocial = () => {
		const [records, setRecords] = useState(null);

		useEffect(() => {
			const fetchData = async () => {
				const data = await getRecords(AIRTABLE_TABLE_VIDEOSOCIAL);
				setRecords(data);
			};
			fetchData();
		}, []);

		if (!records) {
			return (
				<div>
					<ComponentLoader />
				</div>
			);
		} else {
			return records.map((r, i) => {
				if (r.fields.MostraSuSito) {
					return (
						<div className="social-single-item" key={i}>
							{r.fields.VideoID ? (
								<div className="video w-100" style={{ height: `${height}px` }}>
									<iframe
										title={r.fields?.Name ? r.fields.Name : " "}
										src={`https://player.vimeo.com/video/${r.fields?.VideoID}?byline=0`}
										height="100%"
										width="100%"
										frameBorder="0"
										allow="fullscreen; picture-in-picture"
										allowFullScreen
									/>
								</div>
							) : (
								<img
									className="w-100 foto"
									src={r.fields["Foto"][0].url}
									alt={r.fields.Name}
									key={i}
								/>
							)}
						</div>
					);
				}
				return null;
			});
		}
	};
	return (
		<div className="first-element">
			<TopDescription
				title="SOCIAL"
				description={`Benvenuti nel mondo della creazione dei contenuti per i social media. Siamo specializzati nella creazione di contenuti accattivanti e coinvolgenti per TikTok, Instagram e Facebook. 
				La nostra esperienza nel marketing si mescola con la creatività, lavoriamo a stretto contatto con i nostri clienti per garantire che i contenuti che creiamo siano efficaci e raggiungano i risultati desiderati.
				Se stai cercando di aumentare la tua presenza sui social media, sei nel posto giusto.`}
				// cn="pt-4"
			/>
			<div className="container video-scacchiera-social" ref={containerRef}>
				<SingleVideoSocial />
			</div>
		</div>
	);
}
