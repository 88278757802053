import React, { useEffect, useState } from "react";
import { TopDescription } from "../components/top-description";
import MyCarousel from "../components/carousel/carousel";
import { getRecords, AIRTABLE_TABLE_EVENT } from "../helpers/airtable";
import { ComponentLoader } from "../components/loader/loader";

const EventCarouselPlusVideo = () => {
	const [records, setRecords] = useState(null);

	useEffect(() => {
		const fetchData = async () => {
			const data = await getRecords(AIRTABLE_TABLE_EVENT);
			setRecords(data);
		};
		fetchData();
	}, []);

	if (!records) {
		return (
			<div>
				<ComponentLoader />
			</div>
		);
	} else {
		return records.map((r, i) => {
			if (r.fields.MostraSuSito) {
				return (
					<div key={i} className="container single-event-section">
						<h2 className="text-center pb-4">{r.fields.Name}</h2>
						<div
							className={`d-flex row ${i % 2 === 0 && "even"} ${
								(!r.fields["Foto"] || !r.fields.VideoID) &&
								"justify-content-center"
							}`}
						>
							{r.fields["Foto"] && (
								<div className="foto-event-section col pt-4 pt-lg-0 col-lg-6">
									<MyCarousel
										client={r.fields?.Name ? r.fields.Name : `Foto${i}`}
										images={r.fields["Foto"]}
									/>
								</div>
							)}
							{r.fields.VideoID && (
								<div className="col col-lg-6 align-self-center justify-content-center">
									<div className="video">
										<iframe
											title={r.fields?.Name ? r.fields.Name : " "}
											src={`https://player.vimeo.com/video/${r.fields?.VideoID}`}
											height="100%"
											width="100%"
											frameBorder="0"
											allow="autoplay; fullscreen; picture-in-picture"
											allowFullScreen
										/>
									</div>
								</div>
							)}
						</div>
					</div>
				);
			}
			return null;
		});
	}
};

export default function Event() {
	return (
		<div className="first-element" id="event">
			<TopDescription
				title="REPORTAGE EVENTI"
				description="Benvenuti nell'arte della narrazione. Il nostro obiettivo è quello di catturare i momenti più importanti e le emozioni che rendono unico ogni evento. Siamo una società specializzata nella realizzazione di reportage fotografici e video di eventi privati e pubblici."
				// cn="pt-4"
			/>
			<EventCarouselPlusVideo />
		</div>
	);
}
